import React from "react";
import { Link } from "react-router-dom";
import TangerineImage from "../../assets/img/tangerine-logo.svg";
import { ArrowLeft } from "react-feather";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NoorTakaful = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <div class="container-sm">
      <div class="pb-4">
        <button onClick={handleGoBack} className="btn btn-primary ">
          {" "}
          <ArrowLeft /> Back
        </button>
      </div>
      <div class="row">
        <div class="col-md-4 pb-2 pb-lg-0">
          <div
            class="p-5 text-dark rounded-lg h-100 card"
            // style={{ backgroundColor: '#333287' }}
          >
            <div class="d-flex ml-auto pb-4">
              <img
                src={TangerineImage}
                alt="TG insurance"
                style={{ width: "100px" }}
              />
            </div>
            <h3 class="text-dark">Tangerine Vehicle </h3>

            <Link
              to="/vendors/tangerine/vehicle"
              class="btn btn-primary"
              type="button"
            >
              Purchase
            </Link>
          </div>
        </div>

        <div class="col-md-4">
          <div class="p-5 text-dark rounded-lg h-100 card">
            <div class="d-flex ml-auto pb-4">
              <img
                src={TangerineImage}
                alt="AG insurance"
                style={{ width: "100px" }}
              />
            </div>
            <h3 class="text-dark">Local Travel</h3>

            <Link to="/vendors/tangerine/local-travel" class="btn btn-primary">
              Purchase
            </Link>
          </div>
        </div>
        {/* <div class="col-md-4">
          <div class="p-5 text-dark rounded-lg h-100 card">
            <div class="d-flex ml-auto pb-4">
              <img
                src={TangerineImage}
                alt="AG insurance"
                style={{ width: "100px" }}
              />
            </div>
            <h3 class="text-dark">Foreign Travel</h3>

            <Link
              to="/vendors/tangerine/foreign-travel"
              class="btn btn-primary"
            >
              Purchase
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default NoorTakaful;
