import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { change_breadcrum } from '../../store/actions/Bredcrum';
import KennediaComponent from '../../components/Kennedia';

const KennediaPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change_breadcrum('Kennedia Insurance'));
  }, []);

  return <KennediaComponent />;
};

export default KennediaPage;
