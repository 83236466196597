import React, { Component } from "react";
import { BounceRight, FadeIn } from "animate-components";
import { connect } from "react-redux";
import { switch_content } from "../../../../store/actions/SwitchContent";
import { props_params } from "../../../../store/actions/PropsParams";
import { PlusCircle, Edit, Trash2, Eye, EyeOff, Link } from "react-feather";
import Axios from "axios";
import { Home } from "../../../../global/Home";
import Spinner from "../../../../global/Spinner";
import { launch_toaster } from "../../../../store/actions/IsToast";
import { toast_trigger } from "../../../../store/actions/ToastTrigger";
import img from "../../../../assets/img/profile.png";
import { quick_params } from "../../../../store/actions/QuickParams";

class ListUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      loading: false,
      data: [],
      type: "Users",
      type2: "1",
      loading2: false,
    };
  }
  handleChange = (event) => {
    if (event.target.type !== "files") {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "type") {
        this.Reload(event.target.value, this.state.type2);
        this.props.dispatch(quick_params(event.target.value));
      } else if (event.target.name === "type2") {
        this.Reload(this.state.type, event.target.value);
        this.props.dispatch(quick_params(event.target.value));
      }
    }
  };

  Delete(key) {
    if (window.confirm("❌ are you sure you want to archive user?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading2: true });
        Axios.post(
          `${Home}enter-ps/user/deactivateUser`,
          {
            id: key,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            // console.log(res);
            this.setState({ loading2: false });
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  Activate(key) {
    if (window.confirm("❌ are you sure you want to activate user?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading2: true });
        Axios.post(
          `${Home}enter-ps/user/activateUser`,
          {
            id: key,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            // console.log(res);
            this.setState({ loading2: false });
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  LinkNow(key) {
    if (1 > 0) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading2: true });
        Axios.post(
          `${Home}enter-ps/user/linkUser`,
          {
            id: key,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            // console.log(res);
            this.setState({ loading2: false });
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  LoadData = () => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(
        `${Home}enter-ps/user/listUsers/${this.state.type}/${this.state.type2}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, data: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  // Reload = (type, status) => {
  //   let token = '';
  //   if (localStorage.getItem('userToken')) {
  //     token = JSON.parse(localStorage.getItem('userToken'));
  //     this.setState({ loading: true });
  //     Axios.get(`${Home}enter-ps/user/listUsers/${type}/${status}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((res) => {
  //         console.log(res);
  //         this.setState({ loading: false, data: res.data.payload });
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  componentDidMount() {
    this.props.dispatch(quick_params(this.state.type));
    this.LoadData();
  }
  render() {
    //console.log(this.props)
    return (
      <>
        <BounceRight duration="1s" timingFunction="ease-out">
          <div className="card border-0 mb-4 z-index-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <h6 class="lh-5 mg-b-0">List of Users</h6>
                </div>
                <div className="col-md-7">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <div className="input-group">
                        {/* <select
                          onChange={this.handleChange}
                          name="type2"
                          value={this.state.type2}
                          className="form-control form-control-sm mr-1"
                        >
                          <option value="1">Active</option>
                          <option value="0">Archived</option>
                        </select> */}
                        {/* <select
                          onChange={this.handleChange}
                          name='type'
                          value={this.state.type}
                          className='form-control form-control-sm mr-1'
                        >
                          <option value='Employees'>Employees</option>
                          <option value='Line Managers'>Line Managers</option>
                          <option value='Admin'>Admin</option>
                        </select> */}
                        <button
                          onClick={() => this.SwitchContent("user_add", [0])}
                          className="btn btn-primary btn-sm shadow"
                        >
                          <PlusCircle color="white" size={35} /> Add New
                        </button>
                      </div>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.loading ? (
            <div className="p-5">
              <Spinner size={70} />
            </div>
          ) : this.state.data == null ? (
            <div className="p-5">
              <div className="alert alert-warning text-center">
                No User Data Found Yet
              </div>
            </div>
          ) : (
            <div className="row mb-4">
              {this.state.data?.map((user, i) => (
                <div key={i} className="col-md-3 mb-3">
                  <FadeIn duration="1s" timingFunction="ease-out">
                    <div className="card card-profile st-member">
                      {user.status ? (
                        <span
                          data-rh="Archived"
                          className="st-box bg-success"
                        ></span>
                      ) : (
                        <span
                          data-rh="Archived"
                          className="st-box bg-danger"
                        ></span>
                      )}

                      <div className="card-body tx-13">
                        <center>
                          <div className="avatar avatar-lg">
                            <span className="">
                              <img
                                className="avatar-initial rounded-circle"
                                src={
                                  user?.picture !== null ? user?.picture : img
                                }
                              />
                            </span>
                          </div>
                          <h5>
                            <a href="#">{`${user?.first_name} ${user?.last_name}`}</a>
                          </h5>
                          <h5>
                            <span>{`${user?.companyDetails?.name}`}</span>
                          </h5>
                          <small>{user?.email}</small> <br />
                          <small>{user?.phone_number}</small>
                          <hr></hr>
                          <button
                            onClick={() =>
                              this.SwitchContent("user_profile", [user])
                            }
                            className="btn btn-primary m-1 btn-primary2 btn-sm shadow"
                          >
                            View profile
                          </button>
                          {/* {this.state.loading2 ? (
                            <Spinner size={10} />
                          ) : user.status ? (
                            <button
                              onClick={() => this.Delete(user._id)}
                              data-rh='Archive User'
                              type='button'
                              className='btn btn-danger btn-icon btn-sm m-1'
                            >
                              <EyeOff color='white' size={48} />
                            </button>
                          ) : (
                            <button
                              onClick={() => this.Activate(user._id)}
                              data-rh='Activate User'
                              type='button'
                              className='btn btn-success btn-icon btn-sm m-1'
                            >
                              <Eye color='white' size={48} />
                            </button>
                          )} */}
                          {!user?.companyDetails ? (
                            <button
                              onClick={() => this.LinkNow(user._id)}
                              data-rh={`Match User to company`}
                              type="button"
                              className="btn btn-secondary btn-icon btn-sm m-1"
                            >
                              <Link color="white" size={48} />
                            </button>
                          ) : (
                            ""
                          )}
                        </center>
                      </div>
                    </div>
                  </FadeIn>
                </div>
              ))}
            </div>
          )}
        </BounceRight>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ListUsers);
