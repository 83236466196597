import React, { useEffect, useState } from "react";

import Axios from "axios";
import { Home } from "../../../global/Home";
import Spinner from "../../../global/Spinner";
import { toast } from "react-toastify";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { ArrowLeft } from "react-feather";

let token = "";

const HealthIncurance = () => {
  const [loading, setLoading] = useState(false);

  const [UserData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const [HmoData, setHmoData] = useState([]);
  const [selectedHmo, setSelectedHmo] = useState([]);
  const [selectedHmoData, setSelectedHmoData] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");

  const [hmoBenefits, setHmoBenefits] = useState([]);

  const [HmoProviderData, setHmoProviderData] = useState([]);
  const [selectedHmoProvider, setSelectedHmoProvider] = useState([]);
  const [selectedHmoProviderData, setSelectedHmoProviderData] = useState(null);

  const [StateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedStateData, setSelectedStateData] = useState(null);

  const [LgaData, setLgaData] = useState([]);
  const [selectedLga, setSelectedLga] = useState([]);
  const [selectedLgaData, setSelectedLgaData] = useState(null);

  const [title, setTitle] = useState("");
  const [gender, setGender] = useState("");
  const [LgaOfResidence, setLgaOfResidence] = useState("");
  const [LgaOfOrigin, setLgaOfOrigin] = useState("");
  const [file, setFile] = useState("");
  const [nationality, setNationality] = useState("");
  const [company, setCompany] = useState("");
  const [dob, setDob] = useState("");
  const [startDate, setStartDate] = useState("");
  const [marital, setMarital] = useState("");
  const [religion, setReligion] = useState("");
  const [address, setAddress] = useState("");
  const [occupation, setOccupation] = useState("");
  const [country, setCountry] = useState("");
  const [error, setError] = useState("");

  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];

    // Check if a file is selected
    if (selectedFile) {
      if (!selectedFile.type.includes("image")) {
        setError("Please select an image file.");
        return;
      }
      if (selectedFile.size > 1024 * 1024) {
        // 1MB
        setError("File size exceeds the limit 1mb.");
        return;
      }

      // Read the file content
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Extract base64 string
        setFile(base64String); // Set the base64 string in state
        setError(""); // Clear any previous error
      };
      reader.readAsDataURL(selectedFile); // Read file as data URL
    } else {
      setFile(null);
      setError("Please select a file.");
    }
  };

  //Load data of users
  const LoadUserData = () => {
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      // setLoading(true);
      Axios.get(`${Home}enter-ps/user/listUsers/Employees/1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setUserData(res.data.payload);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  // Update selected user data when the selected user changes
  useEffect(() => {
    if (selectedUser && UserData) {
      const user = UserData.find((user) => user.id === selectedUser);
      setSelectedUserData(user);
    } else {
      setSelectedUserData(null);
    }
  }, [selectedUser, UserData]);

  //set the options
  //check if userdata exists before rendering
  const userOptions = UserData
    ? UserData.map((user) => ({
        label: `${user.first_name} ${user.last_name} - ${user.email} -  ${user._id}`,
        value: user._id,
        user_id: user._id,
        user_email: user.email,
        phone_number: user.phone_number,
        first_name: user.first_name,
        last_name: user.last_name,
      }))
    : [];

  //Load data of hmo
  const LoadHmoData = () => {
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      // setLoading(true);
      Axios.get(`${Home}hmos/plans`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setHmoData(res.data.payload);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  // Update selected hmo data when the selected hmo changes
  useEffect(() => {
    if (selectedHmo && HmoData) {
      const hmo = HmoData.find((hmo) => hmo.id === selectedHmo.value);
      setSelectedHmoData(hmo);
    } else {
      setSelectedHmoData(null);
    }
  }, [selectedHmo, HmoData]);

  //set the options
  //check if userdata exists before rendering
  const HmoOptions = HmoData
    ? HmoData.map((hmo) => ({
        label: `${hmo.name} `,
        value: hmo.id,
        id: hmo.id,
        three_month_rate: hmo.six_month_rate,
        six_month_rate: hmo.six_month_rate,
        one_year_rate: hmo.six_month_rate,
      }))
    : [];

  // Update prices based on the selected plan
  const priceOptions = selectedHmoData
    ? [
        {
          label: `${selectedHmoData.name} (3 Months - ${selectedHmoData.three_month_rate})`,
          value: selectedHmoData.three_month_rate,
        },
        {
          label: `${selectedHmoData.name} (6 Months - ${selectedHmoData.six_month_rate})`,
          value: selectedHmoData.six_month_rate,
        },
        {
          label: `${selectedHmoData.name} (1 Year - ${selectedHmoData.one_year_rate})`,
          value: selectedHmoData.one_year_rate,
        },
      ]
    : [];

  // Load data of HMO Benefits based on selected HMO plan
  const LoadHmoBenefits = () => {
    if (selectedHmo.id && localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      // setLoading(true);
      Axios.get(
        `${Home}hmos/plan/benefits?insurance_plan_id=${selectedHmo.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setHmoBenefits(res.data.payload);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  // Load data of HMO Providers based on selected HMO plan
  const LoadHmoProvider = () => {
    if (selectedHmo.id && localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      // setLoading(true);
      Axios.get(
        `${Home}hmos/plan/providers?insurance_plan_id=${selectedHmo.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setHmoProviderData(res.data.payload);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  // Update selected hmo provider data when the select changes
  useEffect(() => {
    if (selectedHmoProvider && HmoProviderData) {
      const hmoProvider = HmoProviderData.find(
        (provider) => provider.id === selectedHmoProvider
      );
      setSelectedHmoProviderData(hmoProvider);
    } else {
      setSelectedHmoProviderData(null);
    }
  }, [selectedHmoProvider, HmoProviderData]);

  //set the options
  //check if userdata exists before rendering
  const HmoProviderOptions = HmoProviderData
    ? HmoProviderData.map((provider) => ({
        label: `${provider.name} - (Address: ${provider.address}, State: ${provider.state})`,
        value: provider.id,
        id: provider.id,
        state: provider.state,
        address: provider.address,
        identifier: provider.identifier,
      }))
    : [];

  //Load data of States
  const LoadStates = () => {
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      // setLoading(true);
      Axios.get(`${Home}states?vendor=CONSOLIDATED_HALLMARK`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setStateData(res.data.payload);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  // Update selected State data when the select changes
  useEffect(() => {
    if (selectedState && StateData) {
      const states = StateData.find((state) => state.id === selectedState);
      setSelectedStateData(states);
    } else {
      setSelectedStateData(null);
    }
  }, [selectedState, StateData]);

  //set the options
  //check if statedata exists before rendering
  const StateOptions = StateData
    ? StateData.map((state) => ({
        label: state.name,
        value: state.id,
        id: state.id,
        name: state.name,
        hallmark_identifier: state.hallmark_identifier,
      }))
    : [];

  //Load data of LGAs
  const LoadLGA = () => {
    if (selectedState.id && localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      // setLoading(true);
      Axios.get(
        `${Home}lgas/${selectedState.id}/states?vendor=CONSOLIDATED_HALLMARK`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setLgaData(res.data.payload);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  // Update selected Lga data when the select changes
  useEffect(() => {
    if (selectedLga && LgaData) {
      const lgas = LgaData.find((lga) => lga.id === selectedLga);
      setSelectedLgaData(lgas);
    } else {
      setSelectedLgaData(null);
    }
  }, [selectedLga, LgaData]);

  //set the options
  //check if userdata exists before rendering
  const LgaOptions = LgaData
    ? LgaData.map((lga) => ({
        label: lga.name,
        value: lga.id,
        id: lga.id,
        state_id: lga.state_id,
        name: lga.name,
        hallmark_identifier: lga.hallmark_identifier,
      }))
    : [];

  //Handle the Buy Hmo Insurance
  const handleBuyHmoPolicy = (e) => {
    e.preventDefault();
    // Check if a user is selected
    if (!selectedUser) {
      toast.error("Please select a user before purchasing car insurance", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
      return;
    }

    const generateRandomString = (length) => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    };

    const randomTransactionID = generateRandomString(12);

    const hmoDatas = {
      insurance_plan_id: selectedHmo.id,
      insurance_plan_provider_id: selectedHmoProvider.id,
      title: title,
      first_name: selectedUser?.first_name,
      last_name: selectedUser?.last_name,
      middle_name: selectedUser?.last_name,
      dob: dob,
      gender: gender,
      marital_status: marital,
      ocupation: occupation,
      religion: religion,
      company: company,
      nationality: nationality,
      state_of_origin: selectedState?.hallmark_identifier,
      state_of_residence: selectedState?.hallmark_identifier,
      lga_of_origin: LgaOfOrigin,
      lga_of_residence: LgaOfResidence,
      country_of_residence: country,
      residential_address: address,
      email: selectedUser?.user_email,
      phone_number: selectedUser?.phone_number,
      duration_in_months: selectedDuration.value,
      start_date: startDate,
      amount: selectedPrice?.value,
      user_id: selectedUser?.user_id,
      passport: file,
      transaction_id: randomTransactionID,
    };
    //console.log(`this is the HmoData: ${JSON.stringify(hmoDatas)}`);

    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      setLoading(true);
      Axios.post(`${Home}hmos/buy`, hmoDatas, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          toast.success("Buy Hmo Plan successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
          setLoading(false);
          console.log(res);

          // resetForm();
          history.push("/policies");
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error buying Hmo Policy", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
          setLoading(false);
        });
    }
  };

  //Load endpoints
  useEffect(() => {
    LoadUserData();
    LoadHmoData();
    LoadStates();
  }, []);

  // Load HMO Provider data when the selected HMO plan changes
  useEffect(() => {
    LoadHmoProvider();
    LoadLGA();
    LoadHmoBenefits();
  }, [selectedHmo, selectedState]);

  return (
    <>
      <div class="pb-4">
        <button onClick={handleGoBack} className="btn btn-primary ">
          {" "}
          <ArrowLeft /> Back
        </button>
      </div>
      <form
        onSubmit={handleBuyHmoPolicy}
        className="card container w-100 p-5 m-auto flex flex-column"
      >
        <div className="form-group flex-column flex mb-5">
          <label className="form-label" htmlFor="user-select">
            Select User
          </label>
          <Select
            isSearchable
            id="user-select"
            className=" z-index-1"
            style={{ width: "100%" }}
            value={selectedUser}
            onChange={(selectedOption) => setSelectedUser(selectedOption)}
            options={userOptions}
            placeholder="Select User"
          />
        </div>
        <div className="form-group flex-column flex mb-5">
          <label className="form-label" htmlFor="user-select">
            Select Hmo plan
          </label>
          <Select
            isSearchable
            id="hmo-select"
            className="w-100 z-index-1"
            style={{ width: "105%" }}
            value={selectedHmo}
            onChange={(selectedOption) => setSelectedHmo(selectedOption)}
            options={HmoOptions}
            placeholder="Select User"
          />
        </div>

        {/* Plan Benefits  */}
        {hmoBenefits.length > 0 ? (
          <div className="plan-benefits card  text-white">
            <table className="table table-sm table-primary table-bordered table-striped rounded-lg table-responsive">
              <>
                <thead>
                  <tr
                    style={{ fontSize: "18px" }}
                    className="text-left text-dark font-weight-bold"
                  >
                    <th style={{ width: "30%" }}> Name</th>
                    <th style={{ width: "30%" }}>Group Name</th>
                    <th style={{ width: "30%" }}>Description</th>
                    <th style={{ width: "30%" }}>Product Limit</th>
                  </tr>
                </thead>

                <tbody>
                  {hmoBenefits.map((benefit, i) => (
                    <tr
                      style={{ fontSize: "14px" }}
                      className="text-left text-dark "
                    >
                      <td>{benefit?.name}</td>
                      <td>{benefit?.group_name}</td>
                      <td>{benefit?.description}</td>
                      <td> {benefit?.limit}</td>
                    </tr>
                  ))}
                </tbody>
              </>
            </table>
          </div>
        ) : (
          []
        )}

        {/* Plan Benefits End*/}

        {/* Select price */}
        <div className="form-group">
          <label>Select Price</label>
          <Select
            value={selectedPrice}
            className="w-100 z-index-1"
            style={{ width: "105%" }}
            onChange={(selectedOption) => setSelectedPrice(selectedOption)}
            options={priceOptions}
          />
        </div>

        {/* Select duration */}
        <div className="form-group">
          <label>Select Duration</label>
          <Select
            value={selectedDuration}
            className="w-100 z-index-1"
            style={{ width: "105%" }}
            onChange={(selectedOption) => setSelectedDuration(selectedOption)}
            options={[
              {
                label: `3 Months - ${selectedHmoData && selectedHmoData.name}`,
                value: 3,
              },
              {
                label: `6 Months - ${selectedHmoData && selectedHmoData.name}`,
                value: 6,
              },
              {
                label: `1 Year - ${selectedHmoData && selectedHmoData.name}`,
                value: 12,
              },
            ]}
          />
        </div>

        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="provider-select">
            Select HMO Provider
          </label>
          <Select
            isSearchable
            id="provider-select"
            className="w-100 z-index-1"
            value={selectedHmoProvider}
            onChange={(selectedOption) =>
              setSelectedHmoProvider(selectedOption)
            }
            options={HmoProviderOptions}
            placeholder="Select Provider"
          />
        </div>
        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="state-select">
            Select State
          </label>
          <Select
            isSearchable
            id="state-select"
            className="w-100 z-index-1"
            value={selectedState}
            onChange={(selectedOption) => setSelectedState(selectedOption)}
            options={StateOptions}
            placeholder="Select State"
          />
        </div>
        <div className="form-group flex-column flex">
          <label className="form-label" htmlFor="lga-select">
            Select LGA
          </label>
          <Select
            isSearchable
            id="lga-select"
            className="w-100 z-index-1"
            value={selectedLga}
            onChange={(selectedOption) => setSelectedLga(selectedOption)}
            options={LgaOptions}
            placeholder="Select Lga"
          />
        </div>
        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="LgaResidense">
            LGA of Residense
          </label>
          <input
            type="text"
            className="form-control"
            style={{ width: "105%" }}
            placeholder="input LGA of Residense"
            value={LgaOfResidence}
            onChange={(e) => setLgaOfResidence(e.target.value)}
          />
        </div>

        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="LgaOrigin">
            LGA of Origin
          </label>
          <input
            type="text"
            className="form-control"
            style={{ width: "105%" }}
            placeholder="input LGA of Origin"
            value={LgaOfOrigin}
            onChange={(e) => setLgaOfOrigin(e.target.value)}
          />
        </div>

        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="nationality">
            Nationality
          </label>
          <input
            type="text"
            className="form-control"
            style={{ width: "105%" }}
            placeholder="Nationality"
            value={nationality}
            onChange={(e) => setNationality(e.target.value)}
          />
        </div>
        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="title">
            Company
          </label>
          <input
            type="text"
            className="form-control"
            style={{ width: "105%" }}
            placeholder="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </div>

        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="country">
            Country
          </label>
          <input
            type="text"
            className="form-control"
            style={{ width: "105%" }}
            placeholder="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>

        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="address">
            Residential Address
          </label>
          <input
            type="text"
            className="form-control"
            style={{ width: "105%" }}
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="occupation">
            Occupation
          </label>
          <input
            type="text"
            className="form-control"
            style={{ width: "105%" }}
            placeholder="Occupation"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
          />
        </div>

        <div className="form-group flex-column flex mb-5">
          <label className="form-label" htmlFor="Religion">
            Select Religion
          </label>
          <select
            className="form-control  mb-4 z-index-10"
            value={religion}
            style={{ width: "90%" }}
            onChange={(e) => setReligion(e.target.value)}
          >
            <option value="">Select Religion </option>
            <option value="Christian">Christian</option>
            <option value="Muslim">Muslim</option>
          </select>
        </div>

        <div className="form-group flex-column flex mb-5">
          <label className="form-label" htmlFor="marital">
            Select Marital Status
          </label>
          <select
            className="form-control mb-4 z-index-10 "
            style={{ width: "90%" }}
            value={marital}
            onChange={(e) => setMarital(e.target.value)}
          >
            <option value="">Select Marital Status </option>
            <option value="Single">Single</option>
            <option value="Married">Married</option>
            <option value="Divorce">Divorce</option>
          </select>
        </div>

        <div className="form-group flex-column flex mb-5">
          <label className="form-label" htmlFor="title">
            Select Title
          </label>
          <select
            className="form-control mb-4 z-index-10"
            style={{ width: "90%" }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          >
            <option value="">Select Title </option>
            <option value="Mr">Mr</option>
            <option value="Miss">Miss</option>
            <option value="Master">Master</option>
          </select>
        </div>
        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="dob">
            Date of Birth
          </label>
          <input
            type="date"
            className="form-control"
            style={{ width: "105%" }}
            placeholder="Dob"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
        </div>

        <div className="form-group flex-column flex mb-5">
          <label className="form-label" htmlFor={`gender`}>
            Gender
          </label>
          <select
            className="form-control mb-4 z-index-10"
            style={{ width: "90%" }}
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>

        <div className="form-group flex-column flex ">
          <label className="form-label" htmlFor="dob">
            Start Date
          </label>
          <input
            type="date"
            className="form-control"
            style={{ width: "105%" }}
            placeholder="Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>

        <div className="form-group flex-column flex mb-5">
          <label className="form-label" htmlFor="passport">
            Upload Passport
          </label>
          <input
            type="file"
            className="form-control"
            style={{ width: "106%" }}
            onChange={handleFileUpload}
          />
          <span>{file?.name}</span>
          <span className="text-red">{error}</span>
        </div>
        <button className="btn st-btn2 btn-primary shadow" type="">
          Pay Now
        </button>
        {loading && <Spinner />}
      </form>
    </>
  );
};

export default HealthIncurance;
