import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { change_breadcrum } from "../../../store/actions/Bredcrum";
import LocalTravelinsurance from "../../../components/Tangerine/travel/LocalTravel";

const LocalTravel = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change_breadcrum("AG Travel Insurance"));
  }, []);
  return <LocalTravelinsurance />;
};

export default LocalTravel;
