import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { change_breadcrum } from "../../../store/actions/Bredcrum";
import ForeignTravelinsurance from "../../../components/Tangerine/travel/ForeignTravel";

const LocalTravel = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change_breadcrum("AG Travel Insurance"));
  }, []);
  return <ForeignTravelinsurance />;
};

export default LocalTravel;
