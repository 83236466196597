import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { change_breadcrum } from "../../../store/actions/Bredcrum";
import Travelinsurance from "../../../components/IeiInsurance/travel/travelinsurance";

const Travel = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change_breadcrum("IEI Travel Insurance"));
  }, []);
  return <Travelinsurance />;
};

export default Travel;
