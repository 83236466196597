import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change_breadcrum } from '../../../store/actions/Bredcrum';
import HealthInsurance from '../../../components/Kennedia/health/HealthIncurance';

const Health = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change_breadcrum('Kennedia Health Insurance'));
  }, []);

  return <HealthInsurance />;
};

export default Health;
