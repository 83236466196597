import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import KennediaImage from '../../assets/img/KennediaHMO-Logo.png';

const Kennedia = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <div class='container-sm'>
      <div class='pb-4'>
        <button onClick={handleGoBack} className='btn btn-primary '>
          {' '}
          <ArrowLeft /> Back
        </button>
      </div>
      <div class='row'>
        <div class='col-md-4'>
          <div
            class='p-5 text-dark rounded-lg h-100 card'
            // style={{ backgroundColor: '#333287' }}
          >
            <div class='d-flex ml-auto pb-4'>
              <img
                src={KennediaImage}
                alt='Kennedia insurance'
                style={{ width: '100px' }}
              />
            </div>
            <h3 class='text-dark'>Kennedia Health</h3>

            <Link to='/vendors/kennedia/health' class='btn btn-primary'>
              View Vendor
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kennedia;
