import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./assets/css/theme.css";
import "./assets/css/style.css";
// import './assets/css/inonic.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Index from "./pages/Index";
import Login from "./pages/Login";
import LeftNav from "./layout/LeftNav";
import TopNav from "./layout/TopNav";
import Error404 from "./global/Error404";
import Logout from "./pages/Logout";
import Dashboard from "./pages/Dashboard";
import SelfProfilePage from "./pages/SelfProfilePage";
import SettingsRetail from "./pages/SettingsRetail";
import UserPage from "./pages/UserPage";
import CredentialPage from "./pages/CredentialPage";
import DocumentPage from "./pages/DocumentPage";
import PaymentInPage from "./pages/PaymentInPage";
import CatoryPage from "./pages/CatoryPage";
import WithdrawPage from "./pages/WithdrawPage";
import JobsPage from "./pages/JobsPage";
import LicensePage from "./pages/LicensePage";
import QuizPage from "./pages/QuizPage";
import GetStarted from "./pages/GetStarted";
import PolicyPage from "./pages/PolicyPage";
import CompanyPage from "./pages/CompanyPage";
import ClaimRequest from "./pages/ClaimRequest";

import PolicyVendorsPage from "./pages/PolicyVendorsPage";
import AgInsurancePage from "./pages/AgInsurancePage";
import AgVehicle from "./pages/AgInsurancePage/vehicle/Vehicle";
import AgTravel from "./pages/AgInsurancePage/travel/Travel";

import AgMarine from "./pages/AgInsurancePage/marine/Marine";
import IeIPage from "./pages/IeiPage";
import IeIVehicle from "./pages/IeiPage/vehicle/Vehicle";
import IeITravel from "./pages/IeiPage/travel/Travel";
import NoorTakafulPage from "./pages/NoorTakafulPage";
import NoorVehicle from "./pages/NoorTakafulPage/vehicle/Vehicle";
import TangerineVehicle from "./pages/TangerinePage/vehicle/Vehicle";
import TangerineLocalTravel from "./pages/TangerinePage/localTravel/LocalTravel";
import TangerineForeignTravel from "./pages/TangerinePage/foreignTravel/ForeignTravel";
import NoorMarine from "./pages/NoorTakafulPage/marine/Marine";
import HallmarkPage from "./pages/HallmarkPage/index";
import HallmarkHealth from "./pages/HallmarkPage/health/Health";
import KennediaPage from "./pages/KennediaPage/index";
import KennediaHealth from "./pages/KennediaPage/health/Health";
import TangerinePage from "./pages/TangerinePage";

class App extends Component {
  render() {
    // console.log(this.props);
    let login_layout = true;
    if (this.props.data.login_layout === "STEPHEN_WHOCODED") {
      login_layout = false;
    } else {
      login_layout = true;
    }
    // console.log(login_layout);
    return (
      <>
        <div className={login_layout ? "st-bg-gray3 st-scroll" : ""}>
          {login_layout ? <LeftNav /> : ""}

          <div
            className={
              login_layout
                ? `content ht-100v ${this.props.data.mmm ? "stttttg" : ""} pd-0`
                : ""
            }
          >
            {login_layout ? <TopNav /> : ""}
            <div className={login_layout ? "container pd-x-0 p-0" : ""}>
              {login_layout ? <div className="st-empty"></div> : ""}
              <div className={login_layout ? "content-body" : ""}>
                <Switch>
                  <Route path="/" exact component={Login} />
                  <Route path="/logout" exact component={Logout} />
                  <Route path="/login" exact component={Login} />
                  <Route path="/getstarted" exact component={GetStarted} />
                  <Route path="/dashboard" exact component={Dashboard} />
                  <Route path="/profile" exact component={SelfProfilePage} />

                  <Route path="/users" exact component={UserPage} />
                  <Route path="/company" exact component={CredentialPage} />
                  <Route path="/documents" exact component={DocumentPage} />
                  <Route path="/payment-info" exact component={PaymentInPage} />
                  <Route path="/vehicles" exact component={CatoryPage} />
                  <Route path="/policies" exact component={PolicyPage} />
                  <Route path="/license" exact component={LicensePage} />
                  <Route path="/jobs" exact component={JobsPage} />
                  <Route path="/quiz" exact component={QuizPage} />
                  <Route path="/withdrawals" exact component={WithdrawPage} />

                  <Route path="/claimrequests" exact component={ClaimRequest} />
                  <Route path="/vendors" exact component={PolicyVendorsPage} />
                  <Route
                    path="/vendors/aginsurance"
                    exact
                    component={AgInsurancePage}
                  />
                  <Route
                    path="/vendors/aginsurance/vehicle"
                    exact
                    component={AgVehicle}
                  />
                  <Route
                    path="/vendors/aginsurance/travel"
                    exact
                    component={AgTravel}
                  />

                  <Route
                    path="/vendors/aginsurance/marine"
                    exact
                    component={AgMarine}
                  />

                  <Route path="/vendors/iei" exact component={IeIPage} />
                  <Route
                    path="/vendors/iei/vehicle"
                    exact
                    component={IeIVehicle}
                  />
                  <Route
                    path="/vendors/iei/travel"
                    exact
                    component={IeITravel}
                  />
                  <Route
                    path="/vendors/noortakaful"
                    exact
                    component={NoorTakafulPage}
                  />
                  <Route
                    path="/vendors/noortakaful/vehicle"
                    exact
                    component={NoorVehicle}
                  />
                  <Route
                    path="/vendors/noortakaful/marine"
                    exact
                    component={NoorMarine}
                  />

                  <Route
                    path="/vendors/hallmark"
                    exact
                    component={HallmarkPage}
                  />

                  <Route
                    path="/vendors/hallmark/health"
                    exact
                    component={HallmarkHealth}
                  />

                  <Route
                    path="/vendors/kennedia"
                    exact
                    component={KennediaPage}
                  />

                  <Route
                    path="/vendors/kennedia/health"
                    exact
                    component={KennediaHealth}
                  />
                  <Route
                    path="/vendors/tangerine"
                    exact
                    component={TangerinePage}
                  />
                  <Route
                    path="/vendors/tangerine/vehicle"
                    exact
                    component={TangerineVehicle}
                  />
                  <Route
                    path="/vendors/tangerine/local-travel"
                    exact
                    component={TangerineLocalTravel}
                  />
                  <Route
                    path="/vendors/tangerine/foreign-travel"
                    exact
                    component={TangerineForeignTravel}
                  />

                  <Route path="/companies" exact component={CompanyPage} />
                  <Route
                    path="/settings/retail-partnership"
                    exact
                    component={SettingsRetail}
                  />
                  <Route
                    render={function () {
                      return (
                        <div className="st-e-h">
                          <Error404 />
                        </div>
                      );
                    }}
                  />
                </Switch>
              </div>
              {login_layout ? <div className="st-empty-larg"></div> : ""}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(App);
