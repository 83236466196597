import React, { Component } from "react";
import { FadeIn } from "animate-components";
import { connect } from "react-redux";
import { switch_content } from "../../store/actions/SwitchContent";
import { props_params } from "../../store/actions/PropsParams";
import { PlusCircle, Printer } from "react-feather";
import Axios from "axios";
import { Home, formatAmount } from "../../global/Home";
import Spinner from "../../global/Spinner";
import { launch_toaster } from "../../store/actions/IsToast";
import { toast_trigger } from "../../store/actions/ToastTrigger";
import img from "../../assets/svg/policy.svg";

import { withRouter } from "react-router-dom";

import { open_right } from "../../store/actions/OpenRight";
import moment from "moment";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

class ListPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      loading: false,
      data: [],
      name: "",
      modal: "",
      show: "",
      id: 0,
      switch: "",
      filter: "all",
      selectedFilter: "all",
      originalData: [],

      detail: {},
      currentPage: 1,
      itemsPerPage: 8,
    };
  }

  //Number of items per page
  //handle the paginate onclick
  paginate = (index) => {
    this.setState({ currentPage: index + 1 });
  };
  filterData = () => {
    const { selectedFilter, originalData } = this.state;
    let filteredData;

    switch (selectedFilter) {
      case "pending":
        filteredData = originalData.filter((item) => item.status === "pending");
        break;
      case "active":
        filteredData = originalData.filter((item) => item.status === "active");
        break;
      default:
        filteredData = originalData;
    }

    this.setState({ data: filteredData });
  };
  handleFilterChange = (event) => {
    const filter = event.target.value;
    this.setState({ selectedFilter: filter }, () => {
      if (filter === "all") {
        this.resetData();
      } else {
        this.filterData();
      }
    });
  };

  resetData = () => {
    const { originalData } = this.state;
    this.setState({ data: originalData });
  };

  // Filter = (filter) => {
  //   this.LoadData(filter);
  //   this.setState({ filter: filter });
  // };

  handleChange = (event) => {
    if (event.target.type !== "files") {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "type") {
        this.setState({ switch: event.target.value });
      }
    }
  };

  Delete(key) {
    if (window.confirm("❌ are you sure you want to decline?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(
          `${Home}admin/deleteCategory`,
          {
            id: key,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  OpenModal = (name, id) => {
    if (name.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ name: name }), 600);
    } else {
      this.setState({ name: name });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };

  Returned(key) {
    if (window.confirm("Are you sure you want to mark this returned?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(`${Home}auth/settings/returnIssueItems`, {
          token: token,
          id: key,
        })
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData(this.state.filter);
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  LoadData = () => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });

      Axios.get(`${Home}enter-ps/policy/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log("-----", res);
          const originalData = res.data;
          this.setState({ loading: false, data: originalData, originalData });
          $("#hmo").DataTable();
        })
        .catch((err) => console.log(err));
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  componentDidMount() {
    this.LoadData(this.state.selectedFilter);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.quick_params !== this.props.data.quick_params) {
      this.LoadData(this.state.filter);
    }
    if (nextProps.data.switch !== this.props.data.switch) {
      this.LoadData(this.state.filter);
    }
  }

  OpenModal3 = (modal, id) => {
    if (id !== 0 || id > 0) {
      // this.LoadFiles(id);
    }
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal: modal }), 600);
    } else {
      this.setState({ modal: modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ detail: id });
  };

  componentWillUnmount() {
    this.props.dispatch(open_right("Open"));
  }

  navigate = () => {
    this.props.history.push("/vendors");
  };

  render() {
    return (
      <>
        <FadeIn duration="1s" timingFunction="ease-out">
          <div className="card border-0 mb-5">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h6 class="lh-5 mg-b-0">Policy list</h6>
                </div>
                <div className="col-md-12">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={this.navigate}
                        // onClick={() => this.SwitchContent("add_cat", [])}
                        //   onClixpck={() => this.OpenModal3("WHOCODED", '')}
                        className="btn btn-primary"
                      >
                        <PlusCircle color="white" size={35} /> Add New
                      </button>
                    </FadeIn>
                  </div>
                </div>

                {/* <div className='col-md-2'>
                  <select
                    value={this.state.selectedFilter}
                    onChange={this.handleFilterChange}
                    className='form-control'
                  >
                    <option value='all'>All</option>
                    <option value='pending'>Pending</option>
                    <option value='active'>Active</option>
                  </select>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div>
            {this.state.loading ? (
              <div className='p-5'>
                <Spinner size={70} />
              </div>
            ) : this.state.data.length < 1 ? (
              <div className='p-5'>
                <center>
                  <img
                    style={{ width: '30%' }}
                    className='img-fluid w-50'
                    src={img}
                    alt=''
                  />
                </center>

                <div className='pt-4 alert-secondry text-center'>
                  No Policy set yet, Please add policy.
                </div>
              </div>
            ) : (
              <>
                <div className='row mb-4'>
                  {this.state.data.map((user, i) => (
                    <div key={i} className='col-md-3 mb-3'>
                      <FadeIn duration='1s' timingFunction='ease-out'>
                        <div className='card card-profile st-member'>
                          <div className='card-body tx-13'>
                            <center>
                              <div className='st-img-co'>
                                {user?.insurance_type === 'car' ? (
                                  <img
                                    className='img-fluid'
                                    style={{
                                      width: 'auto',
                                      height: '200px',
                                    }}
                                    src={img2}
                                    alt=''
                                  />
                                ) : (
                                  <img
                                    className='img-fluid'
                                    style={{
                                      width: 'auto',
                                      height: '200px',
                                    }}
                                    src={img}
                                    alt=''
                                  />
                                )}
                              </div>
                            </center>
                          </div>
                          <div className='table-responsive'>
                            <table
                              style={{ minHeight: '291px' }}
                              className='table table-sm table-primary mb-0 -word'
                            >
                              <tr>
                                <td className='word1'>Policy Type: </td>
                                <td className='word2'>{user?.plan?.name}</td>
                              </tr>
                              <tr>
                                <td>Policy Premium:</td>
                                <td> {formatAmount(user.total_premium)} </td>
                              </tr>
                              <tr>
                                <td>Policy Status:</td>
                                <td>
                                  {' '}
                                  {user.status == 'pending' ? (
                                    <span className='badge badge-pill badge-warning'>
                                      {user.status}
                                    </span>
                                  ) : (
                                    <span className='badge badge-pill badge-success'>
                                      {user.status}
                                    </span>
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>Holder Name:</td>
                                <td>{`${user.holder?.first_name} ${user.holder?.last_name}`}</td>
                              </tr>
                              <tr>
                                <td>Holder Email:</td>
                                <td>{`${user.holder?.email}`}</td>
                              </tr>
                              <tr>
                                <td style={{ borderBottomLeftRadius: '13px' }}>
                                  End Date:
                                </td>
                                <td style={{ borderBottomRightRadius: '13px' }}>
                                  {moment(user.end_date).format('MMM Do, YYYY')}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </FadeIn>
                    </div>
                  ))}
                </div>
               
              </>
            )}
          </div> */}
          <div>
            {this.state.loading ? (
              <div className="p-5">
                <Spinner size={70} />
              </div>
            ) : this.state.data.length > 0 ? (
              <div>
                <div className="table-responsive">
                  <table className="" id="hmo">
                    <thead className="display-5" style={{}}>
                      <tr>
                        <th>Holder Name</th>
                        <th>Holder Email</th>
                        <th>Holder Phone Number</th>
                        <th>Policy Type</th>
                        <th>Policy Premium</th>
                        <th>Policy No. </th>x<th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        {/* <th>Print Policy</th> */}
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.data.map((user, i) => (
                        <tr>
                          <td style={{ width: "200px" }}>
                            <span>{`${user?.holder?.first_name} ${user.holder?.last_name}`}</span>
                          </td>
                          <td style={{ width: "200px" }}>
                            <span>{user?.holder?.email}</span>
                          </td>
                          <td style={{ width: "200px" }}>
                            <span>{user?.holder?.phone_number}</span>
                          </td>
                          <td style={{ width: "300px" }}>
                            <span>
                              {user?.plan?.name}{" "}
                              {user?.plan?.vendor_plan_id?.toUpperCase()}
                            </span>
                          </td>
                          <td style={{ width: "200px" }}>
                            <span>{formatAmount(user?.total_premium)} </span>
                          </td>
                          <td style={{ width: "300px" }}>
                            <span>{user?.policy_number}</span>
                          </td>

                          <td style={{ width: "200px" }}>
                            <span>
                              {" "}
                              {moment(user?.start_date).format("MMM Do, YYYY")}
                            </span>
                          </td>
                          <td style={{ width: "200px" }}>
                            <span>
                              {" "}
                              {moment(user?.end_date).format("MMM Do, YYYY")}
                            </span>
                          </td>
                          <td style={{ width: "200px" }}>
                            <span
                              className={`p-2 text-white rounded-pill ${
                                user.status === "success"
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                            >
                              {user.status}
                            </span>
                          </td>
                          {/* <td style={{ width: '200px' }}>
                            <a
                              className='text-white'
                              href={user?.policy_certificate}
                              target='_blank'
                            >
                              <button className='btn btn-primary '>
                                <Printer color='white' size={35} /> Print
                              </button>
                            </a>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="p-5">
                <center>
                  <img
                    style={{ width: "30%" }}
                    className="img-fluid w-20"
                    src={img}
                    alt=""
                  />
                </center>
                <div className="pt-4 alert-secondry text-center display-5">
                  No company Policy Cover Lists Yet!!
                </div>
              </div>
            )}
          </div>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default withRouter(connect(mapStoreToProps)(ListPolicy));
