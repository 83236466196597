import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { change_breadcrum } from "../../store/actions/Bredcrum";
import TangerinceComponent from "../../components/Tangerine";

const TangerinePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change_breadcrum("Tangerine Insurance"));
  }, []);

  return <TangerinceComponent />;
};

export default TangerinePage;
